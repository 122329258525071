.send-email-link {
    text-decoration: underline;
    cursor: pointer;
    display: none;
}

.send-email-link-active {
    display: inline;
}

.email-sent-message {
    display: none;
}

.email-sent-message-active {
    display: inline;
}

.form-alert {
    margin-top: 20px;
    display: none;
}

.form-alert-active {
    display: block;
}

.launcher-form-button {
    margin-left: 8px;
}
